// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-adaptation-measure-js": () => import("./../src/components/AdaptationMeasure.js" /* webpackChunkName: "component---src-components-adaptation-measure-js" */),
  "component---src-components-adaptation-measures-js": () => import("./../src/components/AdaptationMeasures.js" /* webpackChunkName: "component---src-components-adaptation-measures-js" */),
  "component---src-components-climate-observations-js": () => import("./../src/components/ClimateObservations.js" /* webpackChunkName: "component---src-components-climate-observations-js" */),
  "component---src-components-climate-projections-js": () => import("./../src/components/ClimateProjections.js" /* webpackChunkName: "component---src-components-climate-projections-js" */),
  "component---src-components-learn-more-page-js": () => import("./../src/components/LearnMorePage.js" /* webpackChunkName: "component---src-components-learn-more-page-js" */),
  "component---src-components-markdown-page-js": () => import("./../src/components/MarkdownPage.js" /* webpackChunkName: "component---src-components-markdown-page-js" */),
  "component---src-components-quiz-end-js": () => import("./../src/components/QuizEnd.js" /* webpackChunkName: "component---src-components-quiz-end-js" */),
  "component---src-components-quiz-question-js": () => import("./../src/components/QuizQuestion.js" /* webpackChunkName: "component---src-components-quiz-question-js" */),
  "component---src-components-quiz-theme-js": () => import("./../src/components/QuizTheme.js" /* webpackChunkName: "component---src-components-quiz-theme-js" */),
  "component---src-components-yield-compilation-js": () => import("./../src/components/YieldCompilation.js" /* webpackChunkName: "component---src-components-yield-compilation-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("./../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-quiz-js": () => import("./../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-styleguide-js": () => import("./../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

